<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_inline_block">
          <div class="mainList_operation_search">
            <div class="search_center">
              <span>部门：</span>
              <DeptCascader
                :placeholder="'请选择部门'"
                :deptList="deptList"
                v-model="parameter.deptId"
              />
            </div>
            <div class="search_center">
              <span>姓名：</span>
              <el-input
                v-model="parameter.userName"
                class="ipt_width"
                placeholder="请输入姓名"
                clearable
              >
              </el-input>
            </div>
            <div class="search_center">
              <span>单据编号：</span>
              <el-input
                v-model="parameter.expenseNumber"
                class="ipt_width expense__width"
                placeholder="请输入单据编号"
                clearable
              >
              </el-input>
            </div>
            <div class="search_center">
              <span>项目名称：</span>
              <el-select
                v-model="parameter.projectId"
                clearable
                filterable
                placeholder="请输入或选择项目名"
                class="ipt_width project_width"
              >
                <el-option
                  v-for="item in projectList"
                  :key="item.id"
                  :label="item.projectName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>

            <div class="search_center">
              <span>收款单位：</span>
              <el-input
                v-model="parameter.collectionUnit"
                class="ipt_width expense__width"
                placeholder="请输入收款单位"
                clearable
              >
              </el-input>
            </div>
            <div class="search_center">
              <span>申请时间：</span>
              <el-date-picker
                class="date_width"
                v-model="createdDate"
                :pickerOptions="pickerOptions"
                type="daterange"
                range-separator="至"
                start-placeholder="申请日期（始）"
                end-placeholder="申请日期（末）"
              >
              </el-date-picker>
            </div>
            <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)"
              >搜索</el-button
            >
            <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
          </div>
        </div>
        <div class="fr">
          <el-button type="primary" @click="dialogVisible = true">申请流程</el-button>
        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-tabs v-model="parameter.approvalType">
            <el-tab-pane name="UNDETERMINED" label="未审核"></el-tab-pane>
            <el-tab-pane name="APPROVAL_PASS" label="已审核"></el-tab-pane>
            <el-tab-pane name="APPROVAL_REJECT" label="不通过"></el-tab-pane>
          </el-tabs>
          <el-table
            v-loading="loading"
            ref="multipleTable"
            tooltip-effect="dark"
            height="string"
            border
            :data="tableData"
          >
            <el-table-column type="index" label="序号" align="center" width="50"></el-table-column>
            <el-table-column
              prop="expenseNumber"
              label="单据编号"
              align="center"
              width="175"
            ></el-table-column>
            <el-table-column
              prop="createName"
              sortable
              width="100"
              label="申请人"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="companyType"
              sortable
              label="申请单位"
              width="150"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.companyType | dict(dictData.companyType) }}
              </template>
            </el-table-column>
            <el-table-column prop="deptName" sortable label="部门" align="center"></el-table-column>
            <el-table-column
              prop="projectName"
              sortable
              label="项目名称"
              :show-overflow-tooltip="false"
              min-width="320"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="collectionUnit"
              sortable
              min-width="320"
              :show-overflow-tooltip="false"
              label="收款单位"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="sumCost" width="110" sortable label="申请金额" align="center">
              <template slot-scope="scope">
                {{ scope.row.sumCost | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column
              prop="projectBudget"
              width="110"
              sortable
              label="申请时间"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.taskDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column align="center" width="150" sortable prop="statusName" label="流程进度">
            </el-table-column>
            <el-table-column label="操作" align="center" fixed="right" width="70">
              <template slot-scope="scope">
                <el-button
                  v-if="parameter.approvalType != 'UNDETERMINED'"
                  type="text"
                  size="small"
                  class="text_Details_Bgc"
                  @click.stop="handleDetails(scope.row)"
                >
                  详情
                </el-button>
                <el-button
                  v-if="parameter.approvalType == 'UNDETERMINED'"
                  type="text"
                  size="small"
                  class="text_Examine_Bgc"
                  @click.stop="handleEdit(scope.row)"
                >
                  审核
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="parameter.total"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>

    <Edit
      v-if="editShow"
      :isShow.sync="editShow"
      :options="form"
      :getTitle="getTitle"
      :isSee="true"
      :isExamine="isExamine"
    ></Edit>

    <el-dialog title="付款申请流程图" :visible.sync="dialogVisible" width="75%">
      <img src="@/assets/项目付款申请流程.png" alt="项目付款申请流程" width="100%" />
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    Edit: () => import('../dlg/Edit.vue'),
    DeptCascader: () => import('@/components/dept/DeptCascader.vue'),
  },
  data() {
    return {
      loading: false, // 表格 加载
      // 查询条件
      parameter: {
        userName: null,
        expenseNumber: null,
        projectId: null,
        deptId: null,
        pageNow: 1,
        pageSize: 20,
        approvalType: 'UNDETERMINED', //1未审核，2已审核，3未通过
        total: 0, // 表格 -- 数据 总数量
        collectionUnit: null,
      },
      pageSize: 0,
      projectList: [], // 项目数据
      deptList: [], // 部门 列表
      tableData: [], // 表格数据
      editShow: false, // "编辑 -- 组件" 隐藏 或 显示
      dialogVisible: false,
      createdDate: [],
      isExamine: false,
      form: {},
      getTitle: '',
      pickerOptions: {
        onPick: v => {
          if (v.minDate && !v.maxDate) {
            this.$set(this.createdDate, 0, v.minDate.getTime())
            this.$set(this.createdDate, 1, '')
          }
        },
      },
      dictData: {
        companyType: []
      }
    }
  },
  provide() {
    return {
      re: this.closeSubgroup,
    }
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.getType()
    this.getProjectList() /** 加载 "项目" 数据 **/
    this.getDeptList() /** 加载 "部门" 列表数据 **/
    this.getTableData() /** 加载 "付款申请审核" 数据 **/
  },
  watch: {
    'parameter.approvalType': {
      // immediate:true,//初始化立即执行
      // deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        if (newVal) {
          this.parameter.pageNow = 1
          this.getTableData()
        }
      },
    },
    tableData: {
      // immediate:true,//初始化立即执行
      deep: true,
      handler: function (newVal, oldVal) {
        let num = newVal.length
        if (num === 0 && this.parameter.pageNow > 1 && this.parameter.pageNow !== 1) {
          this.parameter.pageNow -= 1
          this.getTableData()
        }
      },
    },
    createdDate: {
      deep: true,
      handler: function (val) {
        if (val == null) {
          this.createdDate = []
        }
      },
    },
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  methods: {
    getType(){
      this.$api.dict
        .listSysDictData('COMPANY_TYPE', true)
        .then(res => {
          this.dictData.companyType = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    /** 加载 "项目" 数据 **/
    getProjectList() {
      this.$api.project
        .projectList({
          pageSize: 10000,
          pageNow: 1,
        })
        .then(res => {
          this.projectList = res.data ? res.data : []
        })
        .catch(err => {
          console.log(err)
        })
    },

    /** 加载 "部门" 列表数据 **/
    getDeptList() {
      this.$api.sysDept
        .getTreeDept()
        .then(res => {
          this.deptList = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },

    /** 加载 "付款申请审核" 数据 **/
    getTableData() {
      this.loading = true
      if (this.createdDate && this.createdDate != []) {
        this.parameter.startDate = new Date(this.createdDate[0]).getTime()
        this.parameter.endDate = new Date(this.createdDate[1]).getTime()
      } else if (this.createdDate == null) {
        this.parameter.startDate = ''
        this.parameter.endDate = ''
      }
      this.$api.payment
        .searchTask(this.parameter)
        .then(res => {
          this.loading = false
          this.tableData = res.data ? (res.data.records ? res.data.records : []) : []
          this.parameter.total = res.data ? (res.data.total ? res.data.total : 0) : 0
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    /**  点击 "搜索" 按钮 **/
    handleSearch() {
      this.getTableData() /** 加载 数据 **/
    },

    /**  点击 "重置" 按钮 **/
    handleReset() {
      this.parameter.userName = null
      this.parameter.expenseNumber = null
      this.parameter.projectId = null
      this.parameter.deptId = null
      this.parameter.collectionUnit = null
      this.parameter.startDate = null
      this.parameter.endDate = null
      this.createdDate = []
      this.parameter.pageNow = 1
      this.getTableData() /** 加载 数据 **/
    },

    /**
     * 点击 "表格 -- 详情" 操作
     * @param item      当前数据
     */
    handleDetails(item) {
      this.getTitle = '查看付款申请单详情'
      this.form = item
      this.isExamine = false
      this.editShow = true
    },

    /**
     * 点击 "表格 -- 审核" 操作
     * @param item      当前数据
     */
    handleEdit(item) {
      this.getTitle = '审核付款申请单详情'
      this.form = item
      this.isExamine = true
      this.editShow = true
    },

    /**
     * 点击 "表格 -- 分页" 操作
     * @param page      当前页
     */
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getTableData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getTableData()
    },

    //全局分页保存
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getTableData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    /** 关闭 "编辑" 组件 **/
    closeSubgroup() {
      this.getTableData()
      this.editShow = false
    },
  },
  filters: {
    applyAmount(n) {
      return Number(n).toLocaleString()
    },
  },
}
</script>

<style scoped>
.mainList .mainList_content .mainList_content_sub .el-table {
  height: calc(100% - 93px);
}

.status {
  margin-bottom: 15px;
}
</style>
